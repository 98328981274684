import React, { Component } from 'react';
import {
  Navbar,
} from 'bootstrap-4-react';

import './app-footer.css';

export default class AppFooter extends Component {

  constructor(props) {
    super(props);
    this.sendEvent = props.sendEvent;
  }

  sendClickEvent = () => {
    this.sendEvent('URL', 'link_retsite_click', {"place" : "footer"});
  };

  render() {
    return (
      <Navbar expand="md" className = "justify-content-center" dark bg="dark" fixed="bottom">
        <Navbar.Brand className = "footer-link" target="_blank" onClick={this.sendClickEvent} href="https://retentioneering.com">https://retentioneering.com</Navbar.Brand>
      </Navbar>
    )
  }
}

import { Client } from 'boardgame.io/react';
import { Game } from 'boardgame.io/core';
import React, { Component } from 'react';
import TicTacToeBoard from '../old/TicTacToeBoard';
import Settings from '../old/Settings';

export default class  Gameplay  extends  Component {

  constructor(props) {
    console.log('gameplay constructor');
    super(props);
    this.tmpState = props.gameState;
    this.sendEvent = props.sendEvent;
    this.setNewGame = props.setNewGame;
  }

  UNSAFE_componentWillMount() {  // TODO Think how to make it safe
    this.setNewGame();
    this.sendEvent('URL', 'tech_screen_change_gameplay', {screen: "Gameplay"});
    this.sendEvent('URL', 'pregame_start_game', this.tmpState );
  }

// Return true if `cells` is in a winning configuration.
  IsVictory = (cells) => {
  };

// Return true if all `cells` are occupied.
  IsDraw = (cells) => {
    // eslint-disable-next-line
    return cells.filter(c => c === null).length == 0;
  };

  TicTacToe = Game({
    setup: () => ({
      cells: Array((this.tmpState.gameLevel + 3) * (this.tmpState.gameLevel + 3)).fill(null),
      currSet: new Settings({
        gameLevel: this.tmpState.gameLevel,
        symbolNum: 9,
        colorNum: 5,
        showKey: this.tmpState.showKey ? 1 : 0,
        showHits: this.tmpState.showHits ? 1 : 0,
        gameChipset: this.tmpState.gameChipset
      }),
      sendEvent : this.sendEvent,
      username: this.props.gameState.user.name
    }),

    moves: {
      clickCell(G, ctx, id) {

        // Ensure that we can't overwrite cells.
        if (G.cells[id] === null) {
          // G.cells[id] = ctx.currentPlayer;
          // G.cells[id] = {'<div>' G.colors[G.currentSymbol[1]] G.symbols[G.currentSymbol[0]]</div>};
          G.cells[id] = G.currSet.currentSymbol;
          /*
               G.cells[id] = G.symbols[G.currentSymbol[0]];
               G.cells[id].color = [G.colors[G.currentSymbol[1]]];
         */
          G.currSet.nextSymbol();

          const x = (id % (G.currSet.keyLength + 1));
          const y = Math.floor(id / (G.currSet.keyLength + 1));
          this.endGameFlag=false;
          G.sendEvent('URL', "game_board_click", {
            position_id: id,
            element: `${G.cells[id].symbol}|${G.cells[id].color}`,
            score: G.currSet.gameScore,
            boardSize: G.currSet.boardSize,
            keyLength: G.currSet.keyLength,
            x: x,
            y: y
          });

          // this.queryTracker(G, id);

        }
      },
    },

    flow: {
      endGameIf: (G, ctx) => {
        if (this.IsVictory(G.cells)) {
          return {winner: ctx.currentPlayer};
        }
        if (this.IsDraw(G.cells)) {
          if(!this.endGameFlag){
            this.endGameFlag=true;
            this.sendEvent('URL', 'aftergame_winfinish', {
              finalScore: G.currSet.gameScore
            });

          }

          return {draw: true};
          /*        if(this.props.ctx.gameover && !this.endGameFlag){
            this.endGameFlag=true;
            this.props.G.currSet.sendEvent('URL', 'aftergame_winfinish', {
                finalScore : this.props.G.currSet.gameScore
            });*/
        }
      },
    },
  });

  gameplay = Client({
    game: this.TicTacToe,
    board: TicTacToeBoard,
    numPlayers: 1,
    debug: false
  });

  render () {
    console.log('Gameplay_render:',this.tmpState);
    return (
        <this.gameplay />
    )
  }
}
import React, { Component } from 'react';
import { Modal, Button } from 'bootstrap-4-react';
import FacebookLogin from 'react-facebook-login';
import setCookie from './setCookie';
import './auth.css';


export default class Auth extends Component {
  responseFacebook = (response) => {
    this.props.sendEvent('URL', 'facebook_response', {});
    console.log('responseFacebook', response);
    this.props.onAuth({
      image: response.picture.data.url,
      name: response.name,
      email: response.email
    });
    this.close.click();
    setCookie('username', response.name);
    setCookie('userpic', response.picture.data.url);
  }

  componentClicked = () => {
    this.props.sendEvent('URL', 'facebook_click', {});
  }

  onClose = () => {
    setCookie('do_not_show_auth', true);
    this.props.sendEvent('URL', 'login_close_click', {});
  }

  render() {
    return (
      <div>
        {/* Button trigger Modal */}
        {/*<Button primary data-toggle="modal" data-target="#authModal">Launch modal</Button>*/}

        {/* Modal */}
        <Modal id="authModal" fade>
          <Modal.Dialog>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>Authorization</Modal.Title>
                <Modal.Close>
                  <span aria-hidden="true" onClick={ this.onClose } ref={ el => this.close = el }>&times;</span>
                </Modal.Close>
              </Modal.Header>
              <Modal.Body>
                <FacebookLogin
                  appId="2377618052556940"
                  autoLoad={false}
                  fields="name,email,picture"
                  cssClass="btn btn-primary btn-lg auth-facebook"
                  onClick={this.componentClicked}
                  callback={this.responseFacebook} />
              </Modal.Body>
              <Modal.Footer>
                <Button secondary data-dismiss="modal" onClick={ this.onClose }>Close</Button>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Dialog>
        </Modal>
      </div>
    );
  }
}

import React, { Component, Fragment } from 'react';
// import { Container } from 'bootstrap-4-react';
// import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import _ from 'lodash';

import './app-main-scores.css';

const API_URL = 'https://api.backendless.com/4FFAC3BD-B77E-46C2-FFC7-0085DA9DA600/B83EAC2D-D4B0-5DB6-FF7E-426EFCAC1C00/data/leaderboard';

export default class AppMainScores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      selectedLevel: 1,
      items: [null, null, null, null, null]
    };
    this.sendEvent = props.sendEvent;
  }

  UNSAFE_componentWillMount() {  // TODO Think how to make it safe
    this.sendEvent('URL', 'tech_screen_change_scores', {screen: "Scores"});
  }

  selectLevel(number) {
    console.log(number);
    this.setState({ selectedLevel: number });
    this.load(number);
  }

  load(level) {
    fetch(`${API_URL}?sortBy=score%20desc&where=level%3D${level}&pageSize=100&props=level%2Cscore%2Cname`)
      .then(res => res.json())
      .then(
        result => {
          console.log(result);

          const resultsByPlayer = _.groupBy(result, item => item.name.replace(/\s/g, ''));
          const uniqResultsByPlayer = _.mapValues(resultsByPlayer, scores =>
            _.last(_.sortBy(scores, 'score'))
          );
          const sortedResultsByPlayer = _.reverse(_.sortBy(_.toArray(uniqResultsByPlayer), 'score'));

          this.setState((prevState, props) => {
            prevState.items[level - 1] = sortedResultsByPlayer;
            return prevState;
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          this.setState({
            error
          });
        }
      );
  }


  componentDidMount() {
    this.load(this.state.selectedLevel)
  }

  componentDidUpdate() {
    console.log('cdu');
  }

  render() {
    const items = this.state.items[this.state.selectedLevel - 1];
    return (
      <Fragment>
        <h3 className="mb-3">Scores</h3>

        <ul className="nav nav-pills nav-fill">
          <li className="nav-item" onClick={ () => this.selectLevel(1) }>
            <a className={ `nav-link ${this.state.selectedLevel === 1 ? 'active' : ''}` }
              href="#">Level 1</a>
          </li>
          <li className="nav-item" onClick={ () => this.selectLevel(2) }>
            <a className={ `nav-link ${this.state.selectedLevel === 2 ? 'active' : ''}` }
              href="#">Level 2</a>
          </li>
          <li className="nav-item" onClick={ () => this.selectLevel(3) }>
            <a className={ `nav-link ${this.state.selectedLevel === 3 ? 'active' : ''}` }
              href="#">Level 3</a>
          </li>
          <li className="nav-item" onClick={ () => this.selectLevel(4) }>
            <a className={ `nav-link ${this.state.selectedLevel === 4 ? 'active' : ''}` }
              href="#">Level 4</a>
          </li>
          <li className="nav-item" onClick={ () => this.selectLevel(5) }>
            <a className={ `nav-link ${this.state.selectedLevel === 5 ? 'active' : ''}` }
              href="#">Level 5</a>
          </li>
        </ul>

        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Level</th>
              <th scope="col">Points</th>
            </tr>
          </thead>
          <tbody>
            { items ? items.map((item, index) => 
              <tr key={ `${index}${item.name}` }>
                <th scope="row">{ index + 1 }</th>
                <td>{ item.name }</td>
                <td>{ item.level }</td>
                <td>{ item.score }</td>
              </tr>
            ) : null}
          </tbody>
        </table>

        {items && !items.length && <h3>No winners yet - you'll be the first!</h3>}
      </Fragment>
    )
  }
}

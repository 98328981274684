import React from 'react';

class Chip  {

    constructor(props) {
        this.color = props.color;
        this.symbol = props.symbol;
        this.pos = props.pos;
    }

    getDisplay() {
        //console.log(this.color);
//        return  (<font  key={this.pos} color={this.color}>{`\u00A0`}{this.symbol}{`\u00A0`}</font>);
        return  (<font  key={this.pos} color={this.color}>{this.symbol}</font>);
    }

}

export default Chip;

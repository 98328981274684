import React, { Fragment } from 'react';
import { Modal, Button } from 'bootstrap-4-react';
import getCookie from './../auth/getCookie';

const API_URL = 'https://api.backendless.com/4FFAC3BD-B77E-46C2-FFC7-0085DA9DA600/B83EAC2D-D4B0-5DB6-FF7E-426EFCAC1C00/data/leaderboard';

class TicTacToeBoard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isFinished: false,
        isScoring: null,
        isSaved: false,
        isSubmitting: false,
        username: props.G.username || ''
      };
      this.sendEvent = props.G.sendEvent;
      this.authCountdown = 10;
    }

    onClick(id) {
        if (this.isActive(id)) {
            console.log('ID:' + id);
            this.useGameRadar(id);
            this.props.moves.clickCell(id);
          //   console.log(this.props.G);
         //   console.log(this.getXYbuID(id));
         //   let xy=this.getXYbuID(id);
         //   console.log(this.getIDbyXY(xy.x, xy.y));
            this.props.events.endTurn();
           //  console.log(this.props.G);
            this.endGameFlag=false;
            if (!--this.authCountdown && !this.props.G.username && !getCookie('do_not_show_auth')) {
                this.sendEvent('URL', 'login_popup_auto', {});
                this.auth.click();
            }
            this.setState({ username: getCookie('username') })
        }
    }

    handleUsernameChange = (event) => {
      this.setState({username: event.target.value});
    }

    isActive(id) {
        if (!this.props.isActive) return false;
        return this.props.G.cells[id] === null;

    }

    getXYbuID(id) {

        let x = (id % (this.props.G.currSet.keyLength + 1));
        let y = Math.floor(id / (this.props.G.currSet.keyLength + 1));
        return {
            x: x,
            y: y
        }
    }

    getIDbyXY(x, y) {
        return y * (this.props.G.currSet.keyLength + 1) + x;
    }

    calculateScore(arr) {
        let result = 0;

        for (let i=0; i< arr.length; i++) {
            let b = 1;
            for (let j=arr[i]; j>1; j--) {
                result += j*b;
                b++;
            }

        }
       //  console.log(arr);
       //  console.log('turnScore:', result);

        return result
    }
    strComparer(bStr, kStr,bPos,kPos,cMethod,direction) {
        //bStr -board string
        //kStr - key string
        // bPos - math position in board string
        // kPos - math position in key string
        // cMethod - compare method

        //console.log(bStr, kStr,bPos,kPos,cMethod);
        //left->right
        let tmpScore=0;
        if(cMethod===1){
            for(let i=0;true;i++){
                if(bStr[bPos+i] === null || bStr[bPos+i]===-1 || kStr[kPos+i]=== null) break;
                if(kStr[kPos+i] === undefined || bStr[bPos+i]!==kStr[kPos+i].pos) break;
                for(let j=0;true;j++){
                    if(bStr[bPos-j] === null || bStr[bPos-j]===-1 || kStr[kPos-j]=== null) break;
                    if(kStr[kPos-j] === undefined ||  bStr[bPos-j]!==kStr[kPos-j].pos) break;
                    tmpScore+=(i+j+1);
                    //console.log('i',i,'j',j);
                }
            }
            //console.log(tmpScore);
            tmpScore = (tmpScore===0?tmpScore:(tmpScore-1));
            //console.log(tmpScore);
        }
        console.log('dir',direction,'scr',tmpScore,bStr, kStr,'bPos',bPos,'kPos', kPos);
        return (tmpScore);
    }
    useGameRadar(id) {

        let keyHits = [];

    /*    this.props.G.currSet.secretKey.forEach(function(item,i,arr) {
              //  if (item.pos === this.props.G.currSet.cells[id].pos) {
              //  keyHits.push(i);
              //  }
            console.log('object: ' + this);
            }, this.props);
    */
        for (let i=0; i < this.props.G.currSet.secretKey.length; i++) {

//         console.log('id:' +id);
//         console.log('cell: ' + this.props.G.currSet.currentSymbol.pos);

           if (this.props.G.currSet.secretKey[i].pos === this.props.G.currSet.currentSymbol.pos) {
               keyHits.push(i);
           }

        }

        let xy = this.getXYbuID(id);
        let turnHits = [];
        this.showHit = [];
        this.showHit.push(id);
        let chekString=[];
        let yStart=0;
        let yEnd=0;
        let xStart=0;
        let xEnd=0;
        let newScore=0;


        for (let i=0; i< keyHits.length; i++) {

            // Vertical

            this.hitLen = 0;
            //console.log('keyHits[i] b',keyHits[i])
            //up->down
            chekString=[];
            yStart=0;
            yEnd=this.props.G.currSet.keyLength;
            for (let k =yStart;k<=yEnd;k++){
                if(k===xy.y){
                    chekString.push(this.props.G.currSet.currentSymbol.pos)
                }else{
                    if( this.props.G.cells[this.getIDbyXY(xy.x,k)] !== null) {
                        chekString.push(this.props.G.cells[this.getIDbyXY(xy.x,k)].pos)
                    }else{
                        chekString.push(-1)
                    }
                }
            }
            newScore+=this.strComparer(chekString,this.props.G.currSet.secretKey,xy.y,keyHits[i],1,'up->down');

            //down->up
            chekString=[];
            yStart=this.props.G.currSet.keyLength;
            yEnd=0;
            //console.log("yStart",yStart,"yEnd",yEnd,"this.props.G.currSet.keyLength",this.props.G.currSet.keyLength,"keyHits[i]",keyHits[i]);
            for (let k =yStart;k>=yEnd;k--){
                if(k===xy.y){
                    chekString.push(this.props.G.currSet.currentSymbol.pos)
                }else{
                    if( this.props.G.cells[this.getIDbyXY(xy.x,k)] !== null) {
                        chekString.push(this.props.G.cells[this.getIDbyXY(xy.x,k)].pos)
                    }else{
                        chekString.push(-1)
                    }
                }
            }
            newScore+=this.strComparer(chekString,this.props.G.currSet.secretKey,this.props.G.currSet.keyLength-xy.y,keyHits[i],1,'down->up');

            //left->right
            chekString=[];
            xStart=0;
            xEnd=this.props.G.currSet.keyLength;
            for (let k =xStart;k<=xEnd;k++){
                if(k===xy.x){
                    chekString.push(this.props.G.currSet.currentSymbol.pos)
                }else{
                    if( this.props.G.cells[this.getIDbyXY(k,xy.y)] !== null) {
                        chekString.push(this.props.G.cells[this.getIDbyXY(k,xy.y)].pos)
                    }else{
                        chekString.push(-1)
                    }
                }
            }
            newScore+=this.strComparer(chekString,this.props.G.currSet.secretKey,xy.x,keyHits[i],1,'left->right');

            //right->left
            chekString=[];
            xStart=this.props.G.currSet.keyLength;
            xEnd=0;
            //console.log("yStart",yStart,"yEnd",yEnd,"this.props.G.currSet.keyLength",this.props.G.currSet.keyLength,"keyHits[i]",keyHits[i]);
            for (let k =xStart;k>=xEnd;k--){
                if(k===xy.x){
                    chekString.push(this.props.G.currSet.currentSymbol.pos)
                }else{
                    if( this.props.G.cells[this.getIDbyXY(k,xy.y)] !== null) {
                        chekString.push(this.props.G.cells[this.getIDbyXY(k,xy.y)].pos)
                    }else{
                        chekString.push(-1)
                    }
                }
            }
            newScore+=this.strComparer(chekString,this.props.G.currSet.secretKey,this.props.G.currSet.keyLength-xy.x,keyHits[i],1,'right->left');
            //console.log('keyHits[i]',keyHits[i],"newScore", newScore);



            for (let k =1; (( keyHits[i] + k) < this.props.G.currSet.keyLength) && ((xy.y+k) < (this.props.G.currSet.keyLength + 1)); k++) {
             if (this.props.G.cells[this.getIDbyXY(xy.x,xy.y+k)] === null)   break;

             if (this.props.G.cells[this.getIDbyXY(xy.x,xy.y+k)].pos === this.props.G.currSet.secretKey[keyHits[i] + k].pos) {
                 this.hitLen++;
                 this.showHit.push(this.getIDbyXY(xy.x,xy.y+k));
             } else {
                  break
             }
            }
            for (let k =-1; (( keyHits[i] + k) >= 0) && ((xy.y+k) >= 0); k--) {
                if (this.props.G.cells[this.getIDbyXY(xy.x,xy.y+k)] === null)   break;

                if (this.props.G.cells[this.getIDbyXY(xy.x,xy.y+k)].pos === this.props.G.currSet.secretKey[keyHits[i] + k].pos) {
                    this.hitLen++;
                    this.showHit.push(this.getIDbyXY(xy.x,xy.y+k));
                } else {
                    break
                }
            }

            if (this.hitLen > 0) {
                turnHits.push(this.hitLen+1);
            }
            this.hitLen = 0;

            // Vertical
            for (let k =1; (( keyHits[i] + k) < this.props.G.currSet.keyLength) && ((xy.y-k) >= 0); k++) {
               if (this.props.G.cells[this.getIDbyXY(xy.x,xy.y-k)] === null)   break;

                if (this.props.G.cells[this.getIDbyXY(xy.x,xy.y-k)].pos === this.props.G.currSet.secretKey[keyHits[i] + k].pos) {
                    this.hitLen++;
                    this.showHit.push(this.getIDbyXY(xy.x,xy.y-k));
                    //  console.log(this.hitLen)
                } else {
                    break
                }
            }
           for (let k =-1; (( keyHits[i] + k) >= 0) && ((xy.y-k) < (this.props.G.currSet.keyLength + 1)); k--) {
                if (this.props.G.cells[this.getIDbyXY(xy.x,xy.y-k)] === null)   break;
                if (this.props.G.cells[this.getIDbyXY(xy.x,xy.y-k)].pos === this.props.G.currSet.secretKey[keyHits[i] + k].pos) {
                    this.hitLen++;
                    this.showHit.push(this.getIDbyXY(xy.x,xy.y-k));
                } else {
                    break
                }
            }

            if (this.hitLen > 0) {
                turnHits.push(this.hitLen+1);
            }

            // Gorizontal
            this.hitLen = 0;

            for (let k =1; (( keyHits[i] + k) < this.props.G.currSet.keyLength) && ((xy.x+k) < (this.props.G.currSet.keyLength + 1)); k++) {
                if (this.props.G.cells[this.getIDbyXY(xy.x+k,xy.y)] === null)   break;

                if (this.props.G.cells[this.getIDbyXY(xy.x+k,xy.y)].pos === this.props.G.currSet.secretKey[keyHits[i] + k].pos) {
                    this.hitLen++;
                    this.showHit.push(this.getIDbyXY(xy.x+k,xy.y));
                    //  console.log(this.hitLen)
                } else {
                    break
                }
            }
            for (let k =-1; (( keyHits[i] + k) >= 0) && ((xy.x+k) >= 0); k--) {
                if (this.props.G.cells[this.getIDbyXY(xy.x+k,xy.y)] === null)   break;

                if (this.props.G.cells[this.getIDbyXY(xy.x+k,xy.y)].pos === this.props.G.currSet.secretKey[keyHits[i] + k].pos) {
                    this.hitLen++;
                    this.showHit.push(this.getIDbyXY(xy.x+k,xy.y));
                } else {
                    break
                }
            }

            if (this.hitLen > 0) {
                turnHits.push(this.hitLen+1);
            }
            this.hitLen = 0;

            // Vertical
            for (let k =1; (( keyHits[i] + k) < this.props.G.currSet.keyLength) && ((xy.x-k) >= 0); k++) {
                if (this.props.G.cells[this.getIDbyXY(xy.x-k,xy.y)] === null)   break;

                if (this.props.G.cells[this.getIDbyXY(xy.x-k,xy.y)].pos === this.props.G.currSet.secretKey[keyHits[i] + k].pos) {
                    this.hitLen++;
                    this.showHit.push(this.getIDbyXY(xy.x-k,xy.y));
                    //  console.log(this.hitLen)
                } else {
                    break
                }
            }
            for (let k =-1; (( keyHits[i] + k) >= 0) && ((xy.x-k) < (this.props.G.currSet.keyLength + 1)); k--) {
                if (this.props.G.cells[this.getIDbyXY(xy.x-k,xy.y)] === null)   break;
                if (this.props.G.cells[this.getIDbyXY(xy.x-k,xy.y)].pos === this.props.G.currSet.secretKey[keyHits[i] + k].pos) {
                    this.hitLen++;
                    this.showHit.push(this.getIDbyXY(xy.x-k,xy.y));
                } else {
                    break
                }
            }

            if (this.hitLen > 0) {
                turnHits.push(this.hitLen+1);
            }


        }
        // console.log('turnScore',turnHits);
       //  this.calculateScore(turnHits);
        // console.log(this.showHit);
       //this.props.G.currSet.gameScore += this.calculateScore(turnHits);
        this.props.G.currSet.gameScore +=newScore;
    }

    handleSubmit = (event) => {
      event.preventDefault();
      if (this.props.G.currSet.showKey === 1) return;
      const body = {
        name: this.nameInput.value,
        score: this.props.G.currSet.gameScore,
        level: this.props.G.currSet.gameLevel,
      };
      console.log(body);
      this.sendEvent('URL', 'aftergame_send_score_confirm', body);
      this.setState({ isSubmitting: true });
      fetch(API_URL, { method: 'POST', mode: 'cors', body: JSON.stringify(body) })
        .then(res => res.json())
        .then(
          result => {
            console.log(result);
            this.setState({
              isSaved: true,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          error => {
            this.setState({
              isSubmitting: false,
              isSaved: false,
              error
            });
          }
        );
    };

    handleYes = () => {
        this.setState({ isScoring: true });
    };

    handleNo = () => {
        this.setState({ isScoring: false });
    };

    handleStartNew = () => {
        this.sendEvent('URL', 'aftergame_startnewgame_button_pressed', {});
        document.location.reload();
    };

    render() {
        const isFinished = this.props.ctx.gameover;

        let winner = '';
        if (this.props.ctx.gameover) {
            winner =
                this.props.ctx.gameover.winner !== undefined ? (
                    <div id="winner">Winner: {this.props.ctx.gameover.winner}</div>
                ) : (
                    <div className={'winner'}  id="winner">Game over! Final score is {this.props.G.currSet.gameScore}!</div>
                );
        }


        let next = '';
        if (!this.props.ctx.gameover) {
            next = (
                <div id="next"><font color="#909090">Next:</font>  {this.props.G.currSet.currentSymbol.getDisplay()}</div>
            )
        }

        let fullKey = '';
        if (this.props.G.currSet.showKey === 1) {
            fullKey = ( this.props.G.currSet.getFullKey() )
        }

        // const emptyCell = ({'\u00A0'});
        const emptyCell = `\u00A0\u00A0\u00A0\u00A0\u00A0`;
        //const widthExp = `\u00A0\u00A0`;

        const  cellStyle = {
            border: '1px solid #555',
            width: '50px',
            height: '50px',
            lineHeight: '50px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '200%',
            background: '#ffffff'
        };

        const  cellStyleHit = {
            border: '1px solid #555',
            width: '50px',
            height: '50px',
            lineHeight: '50px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '200%',
            background: '#f0f0f0'
        };
       // console.log(this.props.G.currSet.keyLength);

        let tbody = [];
        for (let i = 0; i < this.props.G.currSet.keyLength+1; i++) {
            let cells = [];
            for (let j = 0; j < this.props.G.currSet.keyLength+1; j++) {
                const id = (this.props.G.currSet.keyLength+1) * i + j;

            if (this.props.G.currSet.showHits === 1) {
                if (this.showHit !== undefined && this.showHit.length > 0) {
                    //   this.cellStyle.background =  '#ffffff';
                    if (this.showHit.indexOf(id) > -1) {
                        //  delete cellStyle.border;
                        cells.push(
                            <td width="50"  style={cellStyleHit} key={id} onClick={() => this.onClick(id)}>
                                {(!!this.props.G.cells[id] ? this.props.G.cells[id].getDisplay() : emptyCell)}
                            </td>
                        );
                    } else {
                        cells.push(
                            <td width="50" style={cellStyle} key={id} onClick={() => this.onClick(id)}>
                                {(!!this.props.G.cells[id] ? this.props.G.cells[id].getDisplay(): emptyCell)}
                            </td>
                        );
                    }

                } else {
                    cells.push(
                        <td width="50" style={cellStyle} key={id} onClick={() => this.onClick(id)}>
                            {(!!this.props.G.cells[id] ? this.props.G.cells[id].getDisplay(): emptyCell)}
                        </td>
                    );

                }
            } else {
                cells.push(
                    <td width="50"  style={cellStyle} key={id} onClick={() => this.onClick(id)}>
                        {(!!this.props.G.cells[id] ? this.props.G.cells[id].getDisplay():emptyCell)}
                    </td>
                );
            }

                // Here
/*
                cells.push(
                    <td style={cellStyle} key={id} onClick={() => this.onClick(id)}>
                        {(!!this.props.G.cells[id] ? this.props.G.cells[id].getDisplay():"")}
                    </td>
                );
*/
   /*             cells.push('<td style="'+ cellStyle+ '"key="'+id+'" onClick="'+(() => this.onClick(id))+'">' + this.props.G.cells[id] + '</td>'
                );
                */
            }
            tbody.push(<tr key={i}>{cells}</tr>);
        }

        return (
            <Fragment>
                <div>
                    <table><tbody><tr><td>
                        <div id="next"><font color="#556600"> Reboard game</font></div>
                        <div id="next"><font color="#909090"> Level: {this.props.G.currSet.gameLevel}</font></div>
                        <div id="next"><font color="#909090"> Score:</font> {this.props.G.currSet.gameScore}</div>
                        {next}
                        {/* this.props.G.currSet.getFullKey() */}
                        {fullKey}
                    </td><td width="20">&nbsp;</td><td valign="top">
                           <table id="board" width={(this.props.G.currSet.keyLength+1)*50}>
                                <tbody>{tbody}</tbody>
                            </table>
                    </td></tr></tbody></table>
                    <div className="text-center">{winner}</div>
                </div>

                {this.state.isScoring === null && isFinished &&
                <div className="pt-5">
                    <h3>Do you want to score this game?</h3>
                    <button type="button" className="btn btn-success mr-2" onClick={ this.handleYes }>Yes</button>
                    <button type="button" className="btn btn-outline-success" onClick={ this.handleNo }>No</button>
                </div>}

                {this.state.isScoring === true && !this.state.isSaved && <form className="form-inline justify-content-center pt-5 pb-5" onSubmit={ this.handleSubmit}>
                  <div className="form-group mb-2 mr-2">
                    <label htmlFor="winnername" className="mr-2">Enter your name:</label>
                    <input value={ this.state.username } onChange={this.handleUsernameChange}  required
                    ref={ node => { this.nameInput = node; } } type="text" className="form-control" id="winnername" placeholder=""/>
                  </div>
                  <button disabled={ this.state.isSubmitting } type="submit" className="btn btn-success mb-2">Confirm</button>
                </form>}

                {this.state.isSaved && <div>
                    <h2>You were added to leaderboard!</h2>
                    <button type="button" className="btn btn-lg btn-primary mt-5" onClick={ this.handleStartNew }>Start new game</button>
                </div>}

                {this.state.isScoring === false && <button type="button" className="btn btn-lg btn-primary mt-5" onClick={ this.handleStartNew }>Start new game</button>}

                <Button hidden primary data-toggle="modal" data-target="#authModal">
                    <span ref={ el => this.auth = el }>Authorize</span>
                </Button>

            </Fragment>
        );
    }
}
export default TicTacToeBoard;
import React, { Component } from 'react';
import {
  Navbar,
  Form,
  Button,
} from 'bootstrap-4-react';

import {Link} from 'react-router-dom';
import Profile from './profile';
import './app-header.css';

export default class AppHeader extends Component {
  onLoginClick = () => {
    this.props.sendEvent('URL', 'login_open_click', {});
  }

  render() {
    return (
      <Navbar expand="md" dark bg="dark" fixed="top">
          <Link to="/" className="logo">Reboardgame</Link>
        <Navbar.Toggler target="#navbarsExampleDefault" />

        <Navbar.Collapse id="navbarsExampleDefault">
          <Navbar.Nav mr="auto">
          </Navbar.Nav>

          { this.props.user.isLoggedIn
              ? <Profile user={ this.props.user }/>
              : <Button primary data-toggle="modal" onClick={ this.onLoginClick } data-target="#authModal">Login</Button>
          }
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

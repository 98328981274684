import React, { Component } from 'react';
import { Container } from 'bootstrap-4-react';
import {Route} from 'react-router-dom';

import './app-main.css';
import AppMainMenu from "../app-main-menu";
import AppMainGameplay from "../app-main-gameplay";
import AppMainSettings from "../app-main-settings";
import AppMainScores from "../app-main-scores";
import AppMainTeam from "../app-main-team";

export default class AppMain extends Component {

    constructor(props) {
        super(props);
        this.changeSettings = props.changeSettings;
        this.changeScreen = props.changeScreen;
        this.sendEvent = props.sendEvent;
        this.setNewGame = props.setNewGame;
    }

  render() {
      const state = this.props.state;
      console.log('main', state);

    return (
      <Container as="main" role="main">
        <div className="main-frame">
            <Route path="/" exact
                   render={() => (
                       <AppMainMenu
                           sendEvent = {this.sendEvent}/>
                   )}  />
            <Route path="/gameplay"  exact
                   render={() => (
                       <AppMainGameplay
                           gameState={state}
                           sendEvent={this.sendEvent}
                           setNewGame={this.setNewGame}/>
                   )}
            />
            <Route path="/settings" exact
                   render={() => (
                       <AppMainSettings
                           gameState={state}
                           changeSettings = {this.changeSettings}
                           changeScreen = {this.changeScreen}
                           currentScreen = "Settings"
                           sendEvent = {this.sendEvent}
                       />
                   )}
            />
            <Route path="/scores" exact
                   render={() => (
                       <AppMainScores
                           sendEvent = {this.sendEvent}/>
                   )}  />
            <Route path="/team" exact
                   render={() => (
                       <AppMainTeam
                           sendEvent = {this.sendEvent}
                       />
                   )}/>
        </div>
      </Container>
    )
  }
}

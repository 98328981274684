import React from 'react';
import Chip from './Chip';
import  Chipset from './Chipset';

class Settings {

    constructor(props) {
        this.gameLevel = props.gameLevel;
        this.symbolNum = props.symbolNum;
        this.colorNum = props.colorNum;
        this.keyLength = props.gameLevel+2;
        this.boardSize = (this.keyLength + 1) * (this.keyLength + 1);
      //  this.gameAbcNum = props.gameLevel;
        this.gameScore = 0;
        this.poolPosition = 0;
        this.chipPool = [];
        this.showKey = props.showKey;
        this.showHits = props.showHits;
        // this.currentSymbol  = new Chip;

        const colors = ['FireBrick', 'DarkOrange', 'Magenta', 'DarkCyan', 'YellowGreen'];

        // this.colors = [];
        // this.symbols = [];
        this.abc = [];

        this.chipset = new Chipset;
        this.gameIcons = this.chipset.getIcons(props.gameChipset);
        console.log(this.gameIcons);
/*
        this.gameIcons = [
            <FontAwesomeIcon icon={faCloud} />,
            <FontAwesomeIcon icon={faHeart} />,
            <FontAwesomeIcon icon={faStar} />,
            <FontAwesomeIcon icon={faComment} />,
            <FontAwesomeIcon icon={faCertificate} />,
            <FontAwesomeIcon icon={faCat} />,
            <FontAwesomeIcon icon={faFish} />,
            <FontAwesomeIcon icon={faCrow} />,
            <FontAwesomeIcon icon={faDog} />,
            <FontAwesomeIcon icon={faTree} />,
        ];
*/
        for(let i=0; i<this.symbolNum; i++) {
            for(let j=0; j<this.colorNum; j++) {
                this.abc[i*this.colorNum+j] = new Chip({
                    color: colors[j],
                    symbol: this.gameIcons[i],
                    pos: i*this.colorNum +j
                })
            }
            // this.symbols[i] = i.toString();
        }

        this.abc = this.arrayShuffle(this.abc);
        // console.log(this.abc);

        let x = Math.floor(Math.random()*(this.abc.length-this.keyLength));
        this.secretKey = this.abc.slice(x, x+ this.keyLength);
        //console.log(this.secretKey);

        for (let i=0; this.chipPool.length < this.boardSize ;i++) {
            this.chipPool = this.chipPool.concat(this.secretKey);
        }

        this.chipPool = this.chipPool.slice(0,this.boardSize+1);
        this.chipPool = this.arrayShuffle(this.chipPool);
        //console.log(this.chipPool);
        this.nextSymbol();
    }

    arrayShuffle(arr) {
            arr.sort(function() {
            return 0.5 - Math.random();
        });
        return arr;
    }

    nextSymbol() {
        this.currentSymbol = this.chipPool[this.poolPosition];
        this.poolPosition++;
    }

    getFullKey() {

         let chips = [];

        for (let i = 0; i < this.keyLength; i++) {
             chips.push(this.secretKey[i].getDisplay());
               // Chips.push("A")
        }

        return (
           <div id="showkey"><font color="#909090"></font>
                {chips}
            </div>
        )

    }
}

export default Settings;
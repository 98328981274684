import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// import { Container } from 'bootstrap-4-react';
// import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
// import { Redirect } from 'react-router-dom';

import './app-main-settings.css';

class AppMainSettings extends Component {

  constructor(props) {
    super(props);
    this.tmpState = props.gameState;
    console.log('constructor settings', props.gameState);
    this.changeSettings = props.changeSettings;
    this.changeScreen = props.changeScreen;
    this.currentScreen = props.currentScreen;
    this.sendEvent = props.sendEvent;
  }

    UNSAFE_componentWillMount() {  // TODO Think how to make it safe
        this.sendEvent('URL', 'tech_screen_change_settings', {screen: "Settings"});
    }

  redirectToHome = () => {
    const { history } = this.props;
    if(history) history.push('/');
  };

  render() {

    const gameState = this.props.gameState;
    console.log('GS:', gameState);
    console.log('TGS:',this.tmpState);

    const hitsOn = `btn ${gameState.showHits ? 'btn-success' : 'btn-secondary'}`;
    const keyOn = `btn ${gameState.showKey ? 'btn-success' : 'btn-secondary'}`;

    return (
        <span>
    <div className="todo-list-item">
    <span className="todo-list-item-label"> Level : </span>
    <div className="btn-group mr-2 btn-group-sm"  role="group" aria-label="First group">
    <button type="button" className="btn btn-secondary"
            onClick = {() => {this.changeSettings('levelDown')}}
    >-</button>
      <div className="gameLevel"> {gameState.gameLevel}</div>
    <button type="button" className="btn btn-secondary"
            onClick = {() => {this.changeSettings('levelUp')}}
    >+</button>
  </div>

  </div>
  <div className="todo-list-item">
    <span className="todo-list-item-label"> Chipset : </span>
    <div className="btn-group mr-2 btn-group-sm"  role="group" aria-label="First group">
    <button type="button" className="btn btn-secondary"
            onClick = {() => {this.changeSettings('chipsetDown')}}
    >&lt;&lt;</button>
      <div className="gameLevel"> {gameState.gameChipset}</div>
    <button type="button" className="btn btn-secondary"
            onClick = {() => {this.changeSettings('chipsetUp')}}
    >&gt;&gt;</button>
  </div>

  </div>
    <div className="todo-list-item">
        <span className="todo-list-item-label"> Show Hits : </span>
         <div className="btn-group mr-2 btn-group-sm"  role="group" aria-label="First group">
        <button type="button" className={hitsOn}
        onClick = {() => {this.changeSettings('showHits')}}
        >
          {gameState.showHits ? 'ON' : 'OFF'}
        </button>
         </div>
    </div>
        <div className="todo-list-item">
        <span className="todo-list-item-label"> Show Key : </span>
        <div className="btn-group mr-2 btn-group-sm"  role="group" aria-label="First group">
        <button type="button" className={keyOn}
                onClick = {() => {this.changeSettings('showKey')}}>
        {gameState.showKey ? 'ON' : 'OFF'}
  </button>
        </div>
          <div>
            <button type="button" className="btn btn-success"
                    onClick = {this.redirectToHome}>
            OK</button>
            </div>
  </div>
</span>
    );
  }
}
export default withRouter(AppMainSettings)

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import { Container } from 'bootstrap-4-react';
// import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';

import './app-main-team.css';

class AppMainTeam extends Component {

    constructor(props) {
        super(props);
        this.sendEvent = props.sendEvent;
    }

    UNSAFE_componentWillMount() {  // TODO Think how to make it safe
        this.sendEvent('URL', 'tech_screen_change_team', {screen: "Team"});
    }

  redirectToHome = () => {
  //  this.sendEvent('URL','pregame_button_click', {
  //     screen: "Team",
  //     action: "OK"
  //  });
    const { history } = this.props;
    if(history) history.push('/');
  };

    sendClickEvent = () => {
        this.sendEvent('URL', 'link_retsite_click', {"place" : "team page"});
    };

  render() {
    return (
        <div>
        <h3>Team</h3>
        <div>Made by cool guys from Retentioneering team!</div>
          <div> Visit us at</div>
        <div><a href="https://retentioneering.com" onClick={this.sendClickEvent} target="_blank" rel="noopener noreferrer">Retentioneering Team</a></div>
         <div> <button type="button" className="btn btn-success"
                  onClick = {this.redirectToHome}>
           OK</button> </div>
        </div>
    )
  }
}
export default withRouter(AppMainTeam)

import React, { Component } from 'react';

import AppHeader from '../app-header';
import AppMain from '../app-main';
import './app.css';
import AppFooter from "../app-footer";
import {BrowserRouter as Router} from "react-router-dom";
import Chipset from "../old/Chipset";
import Auth from '../auth';
import getCookie from '../auth/getCookie';

const PIXEL_URL = 'https://api.game.retentioneering.com/log.gif';

export default class App extends Component {

    constructor (props) {

        const name = getCookie('username');
        const image = getCookie('userpic');
        let user = { isLoggedIn: false };
        if (name) user = { isLoggedIn: true, name, image };

        super(props);
        this.chipSets = new Chipset;
        const startId = 0;
        this.state={
            gameLevel :  7,
            gameScore :  0,
            showKey   :  true,
            showHits  :  false,
            scoreMethod : 1,
            gameKey   :  [],
            nextSymbol : {},
            currentScreen : "App",
            gameId: 0,
            gameChipsetId : startId,
            gameChipset : this.chipSets.getPresets()[startId],
            user
        };

    }

 /*   componentDidMount() {
    this.initSettings(this.state);
    }
*/

    UNSAFE_componentWillMount() {  // TODO Think how to make it safe
        console.log('Inited');
        this.initSettings(this.state);
        this.simpleCookie(1);
    }

    changeScreen = (screen) => {
        this.setState( (state) => {
            return {
                currentScreen: screen
            }
        });
    };

    setNewGame = () => {
        this.setState((state) => {
            return {
                gameId: state.gameId + 1
            }
         });
    };

    simpleCookie = (bs) =>  {
        let key = "reboard_session_id";
        let value = localStorage.getItem(key);
        if (value == null) {
            value = `${(new Date()).getTime()}|${bs}`;
            localStorage.setItem(key, value);
        }
        // console.log(value);
        return value;
    };

    sendEvent = (eType, name, data) => {

       const timestamp = new Date(Date.now());

        const sendObj = {
            ge_timestamp_event : timestamp.toUTCString(),
            ge_session_id : this.simpleCookie(1),
            ge_user_id : "unknown",
            ge_server_string : "USER_AGENT:",
            ge_url : window.location.href,
            ge_game_config : JSON.stringify({
                gameLevel : this.state.gameLevel,
                showKey : this.state.showKey,
                showHits : this.state.showHits
            }),
            ge_gc_hash : "superhash",
            ge_game_id : this.simpleCookie(1) + "_" + this.state.gameId,
            ge_event_name: name,
            ge_event_prefix: name.split('_')[0],
            ge_event_params:  JSON.stringify(data)
        };

        const typePreset = {
            // url: "http://176.112.192.94:8000/log.gif?data=",
            url: `${PIXEL_URL}?data=`,
            conlog: true,
            msg: false
        };

        if (eType === "URL") {
           fetch(typePreset.url + encodeURIComponent(JSON.stringify(sendObj)), { mode: 'no-cors'}).then(()=> {return true});
           /*    .then((response) => response.json())
               .then((response) => {
                   this.setState({items: response});
                   this.setState({isLoaded: true});
               })
               .then((error) => {
                   this.setState({false: true});
                   this.setState({error});
               })
           */

        } else if (eType === "CONLOG") {
            if (typePreset.conlog) { console.log(sendObj) }
        } else if (eType === "MSG") {
            if (typePreset.msg) { alert(JSON.stringify(sendObj)) }
        } else {
            console.log('sendEvent: Unknown event type', eType);
            return false;
        }

        return true;
    };

   initSettings = () => {
            this.setState(() => {
                return {
                    gameLevel :  1,
                    gameScore :  0,
                    showKey   :  false,
                    showHits  :  true
                }
            });
    };

  changeSettings = (property) => {
      this.setState( (state) => {

          if (property === 'showHits') {
              this.sendEvent('URL', 'pregame_change_settings', {
                  param : "showHits",
                  oldValue: state.showHits,
                  newValue: !state.showHits
              });
              return {showHits : !state.showHits};
          }
          if (property === 'showKey') {
              this.sendEvent('URL', 'pregame_change_settings', {
                  param : "showKey",
                  oldValue: state.showKey,
                  newValue: !state.showKey
              });
              return {showKey : !state.showKey};
          }
          if (property === 'levelUp') {
              const potential = parseInt(state.gameLevel)+1;
              this.sendEvent('URL', 'pregame_change_settings', {
                  param : "level",
                  oldValue: state.gameLevel,
                  newValue: (potential > 0 && potential <16) ? potential : state.gameLevel
              });
              return {gameLevel : (potential > 0 && potential <16) ? potential : state.gameLevel};
          }
          if (property === 'levelDown') {
              const potential = parseInt(state.gameLevel)-1;
              this.sendEvent('URL', 'pregame_change_settings', {
                  param : "level",
                  oldValue: state.gameLevel,
                  newValue: (potential > 0 && potential <16) ? potential : state.gameLevel
              });
              return {gameLevel : (potential > 0 && potential <16) ? potential : state.gameLevel};
          }
              if (property === 'chipsetDown') {
                  let potential = parseInt(state.gameChipsetId)-1;
                  if (potential < 0) {
                      potential = this.chipSets.getPresets().length - 1;
                  }

                  this.sendEvent('URL', 'pregame_change_settings', {
                     param : "chipset",
                     oldValue: state.gameChipset,
                     newValue: this.chipSets.getPresets()[potential]
                  });

                  return {gameChipsetId : potential,
                          gameChipset: this.chipSets.getPresets()[potential]
                    };
              }

              if (property === 'chipsetUp') {
                  let potential = parseInt(state.gameChipsetId)+1;
                  if (potential >= this.chipSets.getPresets().length) {
                      potential = 0;
                  }

                  this.sendEvent('URL', 'pregame_change_settings', {
                      param : "chipset",
                      oldValue: state.gameChipset,
                      newValue: this.chipSets.getPresets()[potential]
                  });

                  return {gameChipsetId : potential,
                      gameChipset: this.chipSets.getPresets()[potential]
                  };
              }

       }
       );
  };

  handleAuth = ({ image, name, email }) => {
    this.setState({
      user: { image, name, email, isLoggedIn: true }
    });
    this.sendEvent('URL', 'auth_success', {});
  }

  render() {

  const state = this.state;

  return (
      <React.Fragment>
          <Router>
        <AppHeader user={ this.state.user } sendEvent={this.sendEvent}/>
        <Auth onAuth={ this.handleAuth } isLoggedIn={ this.state.user.isLoggedIn } sendEvent={this.sendEvent} />
        <AppMain state = {state} changeSettings={this.changeSettings} changeScreen={this.changeScreen} setNewGame={this.setNewGame} sendEvent={this.sendEvent}/>
        <AppFooter sendEvent={this.sendEvent} />
          </Router>
      </React.Fragment>
    );
  }
}


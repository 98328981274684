import React, { Component } from 'react';
import { Media, BImg, BH5 } from 'bootstrap-4-react';

export default class Profile extends Component {
  render() {
    return (
       <Media className="profile">
        <BImg width="32" height="32" src={ this.props.user.image } mr="2" rounded="circle"/>
        <Media.Body>
          <span>{ this.props.user.name }</span>
        </Media.Body>
      </Media>
    );
  }
}
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud, faStar, faHeart, faComment, faCertificate, faCat, faDog, faFish, faCrow, faTree} from '@fortawesome/free-solid-svg-icons'
import { faCaretRight, faChevronCircleDown, faChartLine, faArrowsAltH, faArrowUp,
         faArrowsAltV, faAngleDoubleRight, faArrowAltCircleLeft, faArrowsAlt, faChevronDown} from '@fortawesome/free-solid-svg-icons'
import { faDragon, faFrog, faHippo, faHorse, faPaw, faSpider} from '@fortawesome/free-solid-svg-icons'
import { faAtom, faCapsules, faBrain, faMagnet, faMicroscope, faRadiation, faSyringe, faVial, faVials, faTablets} from '@fortawesome/free-solid-svg-icons'
import { faBug, faCode, faCodeBranch, faCoffee, faFilter, faSitemap, faKeyboard, faLaptopCode, faTerminal, faMicrochip} from '@fortawesome/free-solid-svg-icons'

class Chipset  {

    constructor() {
        this.gameIcons = [];

        this.gameIcons['DefaultMix'] = [ // Не трогать а то все слетит
            <FontAwesomeIcon icon={faCloud} />,
            <FontAwesomeIcon icon={faHeart} />,
            <FontAwesomeIcon icon={faStar} />,
            <FontAwesomeIcon icon={faComment} />,
            <FontAwesomeIcon icon={faCertificate} />,
            <FontAwesomeIcon icon={faCat} />,
            <FontAwesomeIcon icon={faFish} />,
            <FontAwesomeIcon icon={faCrow} />,
            <FontAwesomeIcon icon={faDog} />,
            <FontAwesomeIcon icon={faTree} />,
        ];

        this.gameIcons['Digits'] = ['0','1','2','3','4','5','6','7','8','9'];

        this.gameIcons['Arrows'] = [
            <FontAwesomeIcon icon={faCaretRight} />,
            <FontAwesomeIcon icon={faChevronCircleDown} />,
            <FontAwesomeIcon icon={faChartLine} />,
            <FontAwesomeIcon icon={faArrowsAltH} />,
            <FontAwesomeIcon icon={faArrowUp} />,
            <FontAwesomeIcon icon={faArrowsAltV} />,
            <FontAwesomeIcon icon={faAngleDoubleRight} />,
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />,
            <FontAwesomeIcon icon={faArrowsAlt} />,
            <FontAwesomeIcon icon={faChevronDown} />,
        ];

        this.gameIcons['Animals'] = [
            <FontAwesomeIcon icon={faCat} />,
            <FontAwesomeIcon icon={faDog} />,
            <FontAwesomeIcon icon={faDragon} />,
            <FontAwesomeIcon icon={faFrog} />,
            <FontAwesomeIcon icon={faFish} />,
            <FontAwesomeIcon icon={faHippo} />,
            <FontAwesomeIcon icon={faHorse} />,
            <FontAwesomeIcon icon={faPaw} />,
            <FontAwesomeIcon icon={faSpider} />,
            <FontAwesomeIcon icon={faCrow} />,

        ];

        this.gameIcons['Science'] = [
            <FontAwesomeIcon icon={faAtom} />,
            <FontAwesomeIcon icon={faCapsules} />,
            <FontAwesomeIcon icon={faBrain} />,
            <FontAwesomeIcon icon={faMagnet} />,
            <FontAwesomeIcon icon={faMicroscope} />,
            <FontAwesomeIcon icon={faRadiation} />,
            <FontAwesomeIcon icon={faSyringe} />,
            <FontAwesomeIcon icon={faVials} />,
            <FontAwesomeIcon icon={faVial} />,
            <FontAwesomeIcon icon={faTablets} />,
        ];

        this.gameIcons['Code'] = [
            <FontAwesomeIcon icon={faBug} />,
            <FontAwesomeIcon icon={faCode} />,
            <FontAwesomeIcon icon={faCodeBranch} />,
            <FontAwesomeIcon icon={faCoffee} />,
            <FontAwesomeIcon icon={faFilter} />,
            <FontAwesomeIcon icon={faSitemap} />,
            <FontAwesomeIcon icon={faKeyboard} />,
            <FontAwesomeIcon icon={faLaptopCode} />,
            <FontAwesomeIcon icon={faTerminal} />,
            <FontAwesomeIcon icon={faMicrochip} />,
        ];
    }

    getIcons(denom) {
        return this.gameIcons[denom];
    }

    getPresets() {
        const presets = [];
        for (let item in this.gameIcons) {
           presets.push(item)
        }
        return presets;
    }

}

export default Chipset;

import React, { Component } from 'react';
import { Jumbotron } from 'bootstrap-4-react';
import {Link} from 'react-router-dom';

// import './app-main-menu.css';

export default class AppMainMenu extends Component {

    constructor(props) {
        super(props);
        this.sendEvent = props.sendEvent;
    }

    UNSAFE_componentWillMount() {  // TODO Think how to make it safe
        this.sendEvent('URL', 'tech_screen_change_mainmenu', {screen: "Main menu"});
    }

  render() {
    return (
        <div>
         <Link to="/gameplay"><Jumbotron> Play game</Jumbotron></Link>
         <Link to="/settings"><Jumbotron>Settings</Jumbotron></Link>
         <Link to="/scores"><Jumbotron>High scores</Jumbotron></Link>
         <Link to="/team"><Jumbotron>Team</Jumbotron></Link>
        </div>
    )}
}
